body {
    background-color: #e9f8ff;
    margin: 0px;
    width: 100%;
    font-family: Helvetica;
    color: #06374f;
}

.btn-1 {
    background-color: #0093cc;
    color: #ffffff;
    border-radius: 4px;
    border: solid 1px #0093cc;
}

.btn-1:hover {
    background-color: #ffffff;
    color: #0093cc;
}

.btn-2 {
    color: #0093cc;
    border-radius: 4px;
    border: solid 1px #0093cc;
}

.btn-2:hover {
    background-color: #0093cc;
    color: #ffffff;
}

h1 {
    font-size: 3vw;
    margin-top: 50px;
}

h2 {
    font-size: 1.5vw;
    margin-top: 40px;
}

h3 {
    font-size: 1.2vw;
    margin-top: 40px;
}

.side-img img {
    width: 100%;
}

.form {
    width: calc(100% - 1vw);
    min-height: 300px;
    padding: 20px;
    border-radius: 7px;
    box-shadow: 0 7px 20px 0 rgba(0, 0, 0, 0.08);
    background-color: #ffffff;
    margin-top: 20px;
}

.form-steps div {
    font-size: 14px;
    color: #06374f;
    opacity: 0.5;
}

.form-steps .form-step-selected {
    color: #06374f;
    opacity: 1;
}

label {
    font-size: 16px;
    font-weight: bold;
}

.mobile-image {
    width: 100%;
}

.mobile-image img {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .mobile {
        display: block;
    }
    .web {
        display: none;
    }
    h1 {
        font-size: 21px;
    }
    h2 {
        font-size: 15px;
    }
    h3 {
        font-size: 12px;
    }
}

@media only screen and (min-width: 600px) {
    .mobile {
        display: none;
    }
    .web {
        display: block;
    }
}